import React, { useEffect, useState } from "react";
import Jon from "../../../images/jon.png";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import HeaderCCR from "../../../layout/header/HeaderCCR";
import AuthFooter from "../../auth/AuthFooter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  RequestorDetails,
  PreviewCard,
  InputCheckbox,
  Icon,
  Loading,
  LocationInstructionsModal,
} from "../../../components/Component";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { apiRequest } from "../../../utils/Api";
import { Alert, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import Cookies from "js-cookie";

const VerifyWelcome = ({ ...props }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");
  // console.log(request_id)
  const [loading, setLoading] = useState(true);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationPermission, setLocationPermission] = useState();
  const [location, setLocation] = useState();
  const [micPermission, setmicPermission] = useState();
  const [cameraPermission, setCameraPermission] = useState();
  const [permissionErrors, setPermissionErros] = useState([]);
  const [errorModal, setErrorModal] = useState();
  const [requestData, setRequestData] = useState({
    from_name: "",
    from_company: "",
    from_email: "",
    from_website: "",
    from_title: "",
    to_name: "",
    matter: "",
    reason: "",
    avatar: "",
  });

  const [formData, setFormData] = useState({
    // step: 'welcome',
    request_id: searchParams.get("request_id"),
    tos: false,
    news: false,
    adult: false,
  });

  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const getUserInfo = async () => {
    setLoading(true);
    await apiRequest("/ccr/verify/" + request_id, "GET").then((resp) => {
      if (resp.code === 200) {
        console.log(resp);
        setRequestData(resp.data.request);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    Cookies.remove("at_request", { path: "/", domain: "localhost" });
    Cookies.remove("at_request", { path: "/ccr/verify", domain: ".athenty.com" });
    checkStep();
    window.scrollTo(0, 0, 'smooth');
    getUserInfo();

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          console.log(result);
          if (result.state === "granted") setCameraPermission(true);
          // setCameraPermission(true);
          // result.onchange = () => {
          //     setPermissionStatus(result.state);
          // };
        })
        .catch((err) => {
          console.error("Failed to query permissions", err);
        });
    } else {
      console.error("Permissions API is not supported by your browser");
    }

    // const position = JSON.parse(localStorage.getItem("at_position"));
    // const request = JSON.parse(localStorage.getItem("at_requestor"));
    // console.log(position);
    // if (request && position && position.step !== 0) {
    //   navigate(
    //     "/ccr/verify/form?" +
    //       JSON.parse(localStorage.getItem("at_requestor")).id
    //   );
    // } else {
    //   checkId();
    // }
  }, []);

  const checkStep = async () => {
    await apiRequest(`/ccr/verify/step/check/${request_id}`).then((resp) => {
      console.log(resp);
      if (resp.code === 401) {
        navigate("/errors/401-classic", { state: { message: resp.message } });
      } else if (resp.code === 200) {
        if (resp.data.step === 12) {
          navigate(`/ccr/verify/decision?request_id=${request_id}`);
        } else if (resp.data.step > 0) {
          navigate(`/ccr/verify/form?request_id=${request_id}`);
        } else {
          setLoading(false);
        }
      }
    });
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const onFormSubmit = async (e) => {
    // e.preventDefault()

    // if (!locationPermission || !cameraPermission || !locationPermission) {
    //   if (!locationPermission) {
    //     setPermissionErros((prevItems) => [...prevItems, "camera"]);
    //   }
    //   if (!cameraPermission) {
    //     setPermissionErros((prevItems) => [...prevItems, "mic"]);
    //   }
    //   if (!locationPermission) {
    //     setPermissionErros((prevItems) => [...prevItems, "location"]);
    //   }
    //   setErrorModal(true);
    //   console.log(errorModal);
    //   console.log(permissionErrors);
    //   return;
    // }
    if (!locationPermission) {
      setPermissionErros((prevItems) => [...prevItems, "location"]);
      setErrorModal(true);
      console.log(errorModal);
      console.log(permissionErrors);
      return;
    }
    setSubmitButtonLoading(true);
    setValue("tos", true);
    setValue("news", true);
    await locationAccess();
    setValue("adult", true);
    const location = await locationAccess();
    setValue("latitude", location.latitude);
    setValue("longitude", location.longitude);
    console.log(getValues());

    try {
      apiRequest("/ccr/verify/step/0", "POST", getValues()).then((resp) => {
        // console.log(resp);
        if (resp.code === 200) {
          // console.log(resp.message);
          // console.log(localStorage.getItem("at_request"));
          // localStorage.setItem("at_position", JSON.stringify({ step: 1 }));
          navigate("/ccr/verify/form?request_id=" + request_id);
          // localStorage.setItem("token", fetchResponse.message);
          // navigate('/customer')
          // setLoading(false);
        } else {
          // console.log(resp.message);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitButtonLoading(false);
    }
  };
  const progress = () => {
    console.log("Redirecting to form");
    onFormSubmit();
    // window.location.href='/ccr/verify/form'
  };

  const locationAccess = async () => {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Request high accuracy (GPS)
        timeout: 10000, // Set a timeout of 10 seconds
        maximumAge: 0, // Ensure the result is not a cached position
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          // setValue("location", { latitude, longitude });
          setLocationPermission(true);
          setPermissionErros((prevItems) => prevItems.filter((item) => item !== "location"));
          resolve({ latitude, longitude });
        },
        (err) => {
          // Handle error
          console.log(err);
        },
        options,
      );
    });
  };

  const micAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log(stream);
      setmicPermission(true);
      setPermissionErros((prevItems) => prevItems.filter((item) => item !== "mic"));
    } catch (err) {
      console.log(err);
    }
  };

  const cameraAccess = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setCameraPermission(true);
        setPermissionErros((prevItems) => prevItems.filter((item) => item !== "camera"));
        // Do something with the camera stream
      })
      .catch((err) => {
        console.error("Camera access denied", err);
      });
  };

  const toggleErrorModal = () => {
    setErrorModal(!errorModal);
  };

  return (
    <React.Fragment>
      <Head title="Verification Request" />
      <HeaderCCR></HeaderCCR>
      {loading && <Loading />}
      {!loading && (
        <div>
          <Content>
            <div style={{ paddingTop: "20px" }}>
              {/* <BlockHead size="lg" wide="xs" className="mx-auto"> */}
              <BlockHead size="lg" className="mx-auto w-80">
                {/* <BlockHeadContent className="text-center"> */}
                <BlockHeadContent>
                  <BlockTitle tag="h2" className="fw-normal">
                    Hello {requestData.to_name}
                  </BlockTitle>
                  <BlockDes>
                    <p>You have received an identity verification request from {requestData.from_name}:</p>
                    <RequestorDetails
                      name={requestData.from_name}
                      email={requestData.from_email}
                      title={requestData.from_title}
                      company={requestData.from_company}
                      avatar={requestData.avatar}
                      website={requestData.from_website}
                      matter_id={requestData.matter_id}
                      matter_reason={requestData.matter_reason}
                    ></RequestorDetails>

                    {/* <p className="pt-3">
                  Reason:&nbsp;<b>{requestData.reason}</b>
                  <br></br>
                  Matter:&nbsp;<b>{requestData.matter}</b>
                </p> */}
                    {/* <div className="project-head text-start">
                      <a
                        href="#title"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="project-title"
                      >
                        <div
                          id="at_avatar"
                          className="user-avatar"
                          style={{
                            width: "60px",
                            height: "60px",
                            display: "block",
                          }}
                        >
                          <img id="at-avatar-top-name" src={Jon} alt="" />
                        </div>
                        <div className="project-info">
                          <h6 className="title">{requestData.from_name}</h6>
                          <span className="sub-text"> {requestData.from_title}</span>
                          <span className="sub-text"> {requestData.from_company}</span>
                        </div>
                      </a>
                    </div> */}
                    <div className="project-details text-start">
                      <div>
                        Matter ID:{" "}
                        <span style={{ fontSize: "13px" }} className="text-muted">
                          {requestData.matter_id}
                        </span>
                      </div>

                      <div>
                        Matter Reason:{" "}
                        <span style={{ fontSize: "13px" }} className="text-muted">
                          {requestData.matter_reason}
                        </span>
                      </div>
                    </div>
                  </BlockDes>
                </BlockHeadContent>
              </BlockHead>
              <Block>
                <div style={{ margin: "auto", width: "80%" }}>
                  {/* <PreviewCard style={{ borderWidth: "0px" }}> */}
                  <div className="entry">
                    <p>
                      Keep your identify and property safe with Athenty! You no longer need to share your ID documents
                      or personal info via email or provide paper or electronic copies for unsafe storage or access!
                    </p>
                    {/* <p>
                    You will be guided through several quick steps to complete
                    this verification. Don’t worry! Your progress will be saved
                    as you go. You can securely leave and come back until you
                    submit.
                  </p> */}

                    <h6>To begin, please make sure to follow the instructions closely and have the following ready:</h6>
                    <ul className="list list-sm list-checked">
                      <li>
                        At least one government issued photo ID plus another valid ID document listed here. Make sure
                        that all are actuals (i.e. not photocopies or images), authentic and current (i.e. not expired).
                      </li>
                      <li>
                        Complete this verification from the address in one of your ID documents (if not possible, you
                        will be able to explain why).
                      </li>
                      <li>You are in a quiet well lit room with minimal background noise.</li>
                    </ul>
                    <h6>Verification Steps:</h6>
                    <p>
                      <b>Step 1: </b>1th ID Document<br></br>
                      <b>Step 2: </b>2nd ID Document<br></br>
                      <b>Step 3: </b>About You<br></br>
                      <b>Step 4: </b>Your Location<br></br>
                      <b>Step 5: </b>Contact Information<br></br>
                      <b>Step 6: </b>Facial Recognition<br></br>
                      <b>Step 7: </b>Voice Recognition<br></br>
                      <b>Step 8: </b>Third Parties (if applicable)<br></br>
                      <b>Step 9: </b>Business Profile (if applicable)<br></br>
                      <b>Final Step: </b>Acknowledgement
                    </p>
                    {/* <p>
                    Feel safe knowing that your information, including your ID
                    documents, are encrypted, analyzed, authenticated and only
                    used to verify you; and that it is only shared with <b>
                    {requestData.from_name}</b> and his organization's
                    administrator, who alone control access. The information is
                    stored securely at our CA1 Region Datacenter <b>Toronto,
                    Canada</b>.
                  </p> */}
                    <p>
                      Your information is encrypted, analyzed, authenticated and used to verify you; the results are
                      shared only with <b>{requestData.from_name}</b> and his organization who will administer access,
                      which you will be able to see. The information is stored securely at our <b>CA1</b> Region
                      Datacenter in: <b>Toronto, Canada</b>.
                    </p>
                    <Alert className="alert-icon mt-5" color="primary">
                      <Icon name="alert-circle" />
                      Coming Soon: By downloading our mobile app you will be able to stop further access once your
                      matter is completed.
                    </Alert>
                    <Alert className="alert-icon mt-5" color="primary">
                      <Icon name="alert-circle" />
                      Access to your camera, microphone, and location is mandatory for our app to function optimally.
                      Granting these permissions enables you to fully utilize our features and services. Please click on
                      the buttons below to provide the necessary access.
                      <div className="pt-2">
                        <Button
                          className={`${locationPermission && "disabled"} center`}
                          onClick={() => locationAccess()}
                          width={"250px"}
                          color={locationPermission ? "success" : "danger"}
                          size=""
                          outline=""
                          type="submit"
                          style={{ width: "220px" }}
                        >
                          {!locationPermission ? "Grant Location Access" : "Location Access Granted"}
                        </Button>
                      </div>
                      {/* <div className="pt-2">
                        <Button
                          className={`${micPermission && "disabled"} center`}
                          onClick={() => micAccess()}
                          width={"250px"}
                          color={micPermission ? "success" : "danger"}
                          size=""
                          outline=""
                          type="submit"
                          style={{ width: "220px" }}
                        >
                          {!micPermission ? "Grant Microphone Access" : "Microphone Access Granted"}
                        </Button>
                      </div>
                      <div className="pt-2">
                        <Button
                          className={`${cameraPermission && "disabled"} center`}
                          onClick={() => cameraAccess()}
                          width={"250px"}
                          color={cameraPermission ? "success" : "danger"}
                          size=""
                          outline=""
                          type="submit"
                          style={{ width: "220px" }}
                        >
                          {!cameraPermission ? "Grant Camera Access" : "Camera Access Granted"}
                        </Button>
                      </div> */}
                    </Alert>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                      {/* <InputCheckbox
                      required
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      label="I am over 18 years of age."
                      id="adult"
                    ></InputCheckbox> */}
                      <InputCheckbox
                        required
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                        label={
                          <div>
                            <span>I have read and agree to the </span>
                            <Link to={"https://www.athenty.com/legal/tos"}>Terms</Link>
                            <span>, </span>
                            <Link to={"https://www.athenty.com/legal/privacy"}>Privacy Policy</Link>
                            <span>, and </span>
                            <Link to={"https://www.athenty.com/legal/cookie"}>Cookies Policy</Link>
                          </div>
                        }
                        id="tos"
                      ></InputCheckbox>
                      {/* <InputCheckbox
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      label="I want to receive news, feature updates, discounts, and offers from Athenty."
                      id="news"
                    ></InputCheckbox> */}

                      <div style={{ paddingTop: "10px", paddingBottom: "10px" }} className="center">
                        <Button
                          width={"250px"}
                          color="primary"
                          size=""
                          className={submitButtonLoading && "disabled"}
                          outline=""
                          type="submit"
                        >
                          {submitButtonLoading && <Spinner size="sm" />} Begin ID Verification
                        </Button>
                      </div>
                    </form>
                    {/* <p style={{paddingTop:'20px', fontSize:'10px'}} className="center">If you have any questions, please contact our support team at <a href="mailto://support@athenty.com">support@athenty.com</a></p> */}
                  </div>
                  {/* </PreviewCard> */}
                </div>
              </Block>
            </div>
            <br></br>
            <AuthFooter />
          </Content>
          <Modal backdrop={false} isOpen={errorModal} toggle={() => window.location.reload()}>
            <ModalHeader toggle={() => window.location.reload()}>
              <div>Mandatory Permissions</div>
            </ModalHeader>
            <ModalBody>
              <div>
                To provide you with the best experience, our app requires access to your camera, microphone, and
                location. Please grant access to these options below.
              </div>
              <br></br>
              <div>
                {!locationPermission && <div className="center">Location</div>}
                {/* {!micPermission && <div className="center">Microphone</div>}
                {!cameraPermission && <div className="center">Camera</div>} */}
              </div>
              <LocationInstructionsModal />
              <div className="center pt-3">
                <Button
                  onClick={() => window.location.reload()}
                  id="rejected"
                  color="danger"
                  form="verify-form"
                  className="btn btn-primary me-2 m-1 closer"
                >
                  CLOSE
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};

export default VerifyWelcome;
